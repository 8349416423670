// import moment from 'moment';

export const templeInfo = {
  name: 'Sri Sringeri Shankar Math',
  address: 'University Road, Nallakunta,Hyderabad, Telangana – 500 044',
  contact: 'Phone Numbers: 040-27667783'
};

export const history =
  'The branch math at Nallakunta is the oldest of the branch maths of the Sringeri Math in Hyderabad. The land on which the present Math and temple complex are located were donated to the math by the Late Sri Raja Venkata Rao Limbekar as a token of.....';

export const fullHistory = `The branch math at Nallakunta is the oldest of the branch maths of the Sringeri Math in Hyderabad. The land on which the present Math and temple complex are located were donated to the math by the Late Sri Raja Venkata Rao Limbekar as a token of his love and devotion to the 34th Jagadguru Shankaracharya Sri Sri Chandrashekhara Bharathi Mahaswamiji. Above this, he also constructed the temples of Sri Sharadamba and Sri Adi Shankaracharya.

The Pratishtha kumbhabhishekam of the temples was performed by the 35th Jagadguru Shankaracharya Sri Sri Abhinava Vidyatirtha Mahaswamiji on the auspicious day of Akshaya Tritiya in the year 1960 – April 26 1960.

Since then, the temple and the Math have served as a focal point of spiritual activities to the community of Astikas and followers of Sanatana Dharma in the city of Hyderabad.`;

export const announcements = [
  {
    createdDate: '01-09-2024',
    title: 'The prices for Poojas have been updated',
    information: [
      `The prices for poojas have been updated. In the image below, you could check the new prices of Poojas`
    ],
    src: 'https://lh3.googleusercontent.com/pw/AP1GczMUd5Nq1RQa6LJnMhYDjt_3MFkN7Tj2njCYIQM2CjKWPCSy4szxtFg36ESdi73DIJhNuYcq7R3aD5AcBgTh9ePdiNiBjIrfGC171WKvRhGFspSK51wI6u51GOGQGSLRBpZZpFtPYJh2m0yWuXuhvck=w1129-h400-s-no'
  },
  {
    createdDate: '15-04-2024',
    title: 'Pooja opened for the Sri Rama Kalyanam',
    information: [
      `Pooja (Sita Rama kalyanam) for the Sri Ramanavami 2024 have been opened for booking`
    ]
  },
  {
    createdDate: '29-02-2024',
    title: 'Poojas opened for the Maha shivaratri',
    information: [
      `Poojas for the Mahashivaratri 2024 have been opened for booking`
    ]
  },
  {
    createdDate: '21-01-24',
    title: 'Rama Kalyanam on 22-01-2024',
    information: [
      `Rama kalyanam to be performed at the temple premises on 22-01-2024 on the occassion of Rama Prana Prathishtapana in Ayodhya.
      Sri Rama thryodashi , Sri Sita Ramula Kalyanam and Sri Rama mantra Homamu Purnahuti to be performed. Find the details in the below link:
      `
    ],
    src: 'https://lh3.googleusercontent.com/pw/ABLVV844SVRKpS3xcGu2WigIW1Bio7xdTbtCw9zWCQ0RMywtBX9WdzubSCbcTJPgbGQXuFgyHIGlHtASCKSuhB5SeF5e3sKCjbyqbmAf53AUEN71Tb5WLHFNG2p7IGZa-KNSONSm_hZ_lgkByzLpjY3jSAU=w413-h820-s-no?authuser=0'
  },
  {
    createdDate: '1-01-24',
    title: 'Poojas opened for the calendar year 2024',
    information: [
      `Nitya Poojas for the year 2024 have been opened for booking`
    ]
  },
  {
    createdDate: '1-10-23',
    title: 'Dussehra Navaratri Poojas',
    information: [
      `Poojas for Dussehra Navratri have been opened`
    ]
  },
  {
    createdDate: '21-03-23',
    title: 'Sri Sita Rama Kalyanam',
    information: [
      `Poojas for Sita Rama kalyanam have been opened`
    ]
  },
  {
    createdDate: '14-03-23',
    title: 'Downloading Receipts',
    information: [
      `Devotees can now download receipts from Seva History page for the transactions`
    ]
  },
  {
    createdDate: '14-02-23',
    title: 'Sevas For Mahashivratri have been opened',
    information: [
      `Sevas for Mahashivaratri have been opened for Feb 18, 2023 and Girija Kalyanam on Feb 19, 2023`

    ]
  },
  {
    createdDate: '26-09-22',
    title: 'Dasara sevas opened for booking',
    information: [
      `Following Dasara sevas have been opened on their respective dates: `,
      `1. Lakshma Kumukumarchana will not be performed on 8th November, 2022 (Because of Lunar Eclipse/ Chandra Grahanam)`,
      `2. Karthika Masam dates - 26/10/2022 to 23/11/2022`,
      `3. Chandi Parayana 1 Day for 9 days - 26th September 2022 to 04th October, 2022`,
      `4. Chandi Parayana 9 days from 26th September 2022 to 04th October, 2022 `,
      `5. Dasara Sevas and Sarva Sevas have been opened`,
      `6. Mahabhishekam opened for 25th September, 2022`,
      `7. Dasara Chandi Homa Kanuka seva opened for 4th October, 2022`

    ]
  },
  {
    createdDate: '12-09-21',
    title: 'UPI and Mobile Payment Issues have been resolved',
    information: [
      'Devotees can now book through UPI payments and through any option in the payment gateway. Please report if any issues to the temple authorities.'
    ]
  },
  {
    createdDate: '07-09-21',
    title: 'Pooja Booking dates opened for Ganapathi Navaratri',
    information: [
      'Devotees can book for Ganapathi Navaratri Homam from 11-09-2021 to 18-09-2021 and also for Sahashra Modaka Ganapathi Homa Sahitha Abhishekam on 10-09-2021.'
    ]
  },
  {
    createdDate: '03-03-21',
    title: 'Pooja Booking dates opened for Maha Shivaratri',
    information: [
      'Devotees can book for Lingodbhava Kala Abhishekam on 11th March, 2021 and Girija Kalyanam poojas on 12th March, 2021 .'
    ]
  },
  {
    createdDate: '21-02-21',
    title: 'Pooja Booking dates opened till Dec 31, 2021',
    information: [
      'Devotees can book for regular poojas and special/occassional till December 31, 2021.'
    ]
  }
];
