import { isFriday } from 'date-fns';
import { eachDayOfInterval, startOfDay } from 'date-fns/esm/fp';

const createDate = date => startOfDay(new Date(date));

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}
const getContinuousDaysInRange = (min, max) => {
  if (!isValidDate(min) || !isValidDate(max)) return [];
  return eachDayOfInterval({ start: min, end: max }).map(date =>
    startOfDay(date)
  );
};
const CATEGORIES = {
  SHARADA: 'Sharada Temple',
  SHIVA: 'Shiva Temple',
  GANESH: 'Ganapathi Temple',
  SPECIAL_EVENTS: 'Special Events',
  OTHERS: 'Special General Poojas',
  DASARA_NAVARATRI: 'Dasara Navaratri Poojas',
  GANAPATHI_NAVARATRI: 'Ganapthi Navaratri Poojas',
  MAHASHIVARATRI: 'Maha Shivaratri Special Poojas'
};
export const poojaInfo = [
  {
    label: 'Rudra Abhishekam',
    name: 'Shiva Abhishekam',
    amount: '150',
    availableDates: [],
    category: CATEGORIES.SHIVA
  },
  {
    label: 'Aksharabhyasam',
    name: 'Aksharabhyasam',
    amount: '100',
    availableDates: [],
    category: CATEGORIES.OTHERS
  },
  {
    label: 'Naivedya Sahasram',
    name: 'Naivedya Sahasram',
    amount: '100',
    availableDates: [],
    category: CATEGORIES.SHARADA
  },
  {
    label: 'Pushpa Alankara Seva',
    name: 'Pushpa Alankara Seva',
    amount: '500',
    availableDates: [],
    category: CATEGORIES.SHARADA
  },
  {
    name: 'Pradoshabhishekam',
    amount: '150',
    availableDates: [
      createDate(`01-09-2024`),
      createDate(`01-23-2024`),
      createDate(`02-07-2024`),
      createDate(`02-21-2024`),
      createDate(`03-08-2024`),
      createDate(`03-22-2024`),
      createDate(`04-06-2024`),
      createDate(`04-21-2024`),
      createDate(`05-05-2024`),
      createDate(`05-20-2024`),
      createDate(`06-04-2024`),
      createDate(`06-19-2024`),
      createDate(`07-03-2024`),
      createDate(`07-18-2024`),
      createDate(`08-01-2024`),
      createDate(`08-17-2024`),
      createDate(`08-31-2024`),
      createDate(`09-15-2024`),
      createDate(`09-29-2024`),
      createDate(`10-15-2024`),
      createDate(`10-29-2024`),
      createDate('11-13-2024'),
      createDate('11-28-2024'),
      createDate(`12-13-2024`),
      createDate(`12-28-2024`)
    ],
    category: CATEGORIES.SHIVA
  },
  {
    label: 'Ganapathi Homam  (Shukla Chavithi)',
    name: 'Ganapathi Homam',
    amount: '250',
    availableDates: [
      createDate('01-15-2024'),
      createDate('02-13-2024'),
      createDate('03-14-2024'),
      createDate('04-12-2024'),
      createDate('05-11-2024'),
      createDate('06-10-2024'),
      createDate('07-09-2024'),
      createDate('08-08-2024'),
      createDate('09-07-2024'),
      createDate('10-06-2024'),
      createDate('11-05-2024'),
      createDate('12-05-2024')
    ],
    category: CATEGORIES.GANESH,
    information: 'Performed Every month on Shukla Chavithi'
  },
  {
    label: 'Ganapathi Navaratri Homam',
    name: 'Ganapathi Navaratri Homam',
    amount: '250',
    availableDates: [
      ...getContinuousDaysInRange(
        new Date('09-11-2021'),
        new Date('09-18-2021')
      )
    ],
    category: CATEGORIES.GANESH,
    information: 'Performed only in Ganapathy Navaratri days'
  },
  {
    label: 'Sahashra Modaka Ganapathi Homa Sahitha Abhishekam',
    name: 'Sahashra modaka ganapathi homa sahitha abhishekam',
    amount: '300',
    availableDates: [createDate('09-18-2023')],
    category: CATEGORIES.GANESH,
    information:
      'Performed only in Vinayaka Chavithi (Bhadra pada shuddha chaviti)'
  },
  {
    label: 'Guru Paduka Pooja (Every Shukla Shasti)',
    name: 'Guru Paduka Pooja',
    amount: '300',
    availableDates: [
      createDate('01-16-2024'),
      createDate('02-15-2024'),
      createDate('03-15-2024'),
      createDate('04-14-2024'),
      createDate('05-13-2024'),
      createDate('06-12-2024'),
      createDate('07-12-2024'),
      createDate('08-10-2024'),
      createDate('09-09-2024'),
      createDate('10-09-2024'),
      createDate('11-07-2024'),
      createDate('12-07-2024')
    ],
    category: CATEGORIES.SPECIAL_EVENTS
  },
  {
    label: 'Parayana / Homam (Shukla Saptami - Pournami)',
    name: 'Chandi Parayana',
    amount: '500',
    availableDates: [
      ...getContinuousDaysInRange(
        new Date('01-17-2024'),
        new Date('01-25-2024')
      ),
      ...getContinuousDaysInRange(
        new Date('02-16-2024'),
        new Date('02-24-2024')
      ),
      ...getContinuousDaysInRange(
        new Date('03-16-2024'),
        new Date('03-24-2024')
      ),
      ...getContinuousDaysInRange(
        new Date('04-15-2024'),
        new Date('04-23-2024')
      ),
      ...getContinuousDaysInRange(
        new Date('05-14-2024'),
        new Date('05-22-2024')
      ),
      ...getContinuousDaysInRange(
        new Date('06-13-2024'),
        new Date('06-21-2024')
      ),
      ...getContinuousDaysInRange(
        new Date('07-13-2024'),
        new Date('07-21-2024')
      ),
      ...getContinuousDaysInRange(
        new Date('08-11-2024'),
        new Date('08-19-2024')
      ),
      ...getContinuousDaysInRange(
        new Date('09-10-2024'),
        new Date('09-18-2024')
      ),
      ...getContinuousDaysInRange(
        new Date('10-10-2024'),
        new Date('10-18-2024')
      ),
      ...getContinuousDaysInRange(
        new Date('11-08-2024'),
        new Date('11-16-2024')
      ),
      ...getContinuousDaysInRange(
        new Date('12-08-2024'),
        new Date('12-16-2024')
      ),
    ],
    category: CATEGORIES.SPECIAL_EVENTS
  },
  {
    name: 'Satyanarayana Swamy Vratam',
    amount: '200',
    availableDates: [
      createDate('01-21-2024'),
      createDate('02-20-2024'),
      createDate('03-20-2024'),
      createDate('04-19-2024'),
      createDate('05-19-2024'),
      createDate('06-17-2024'),
      createDate('07-17-2024'),
      createDate('08-15-2024'),
      createDate('09-14-2024'),
      createDate('10-13-2024'),
      createDate('11-12-2024'),
      createDate('12-11-2024')
    ],
    category: CATEGORIES.SPECIAL_EVENTS
  },
  {
    name: 'Pournami Laksha Kumukumarchana',
    amount: '4000',
    availableDates: [
      createDate('01-25-2024'),
      createDate('02-24-2024'),
      createDate('03-25-2024'),
      createDate('04-23-2024'),
      createDate('05-23-2024'),
      createDate('06-22-2024'),
      createDate('07-21-2024'),
      createDate('08-19-2024'),
      createDate('09-18-2024'),
      createDate('10-17-2024'),
      createDate('11-15-2024'),
      createDate('12-15-2024'),
    ],
    category: CATEGORIES.SHARADA
  },
  {
    label: 'Sankata Hara Chaturdi (Bahula Chavithi)',
    name: 'Sankata Hara Chaturdi',
    amount: '150',
    availableDates: [
      createDate('01-29-2024'),
      createDate('02-28-2024'),
      createDate('03-28-2024'),
      createDate('04-27-2024'),
      createDate('05-26-2024'),
      createDate('06-25-2024'),
      createDate('07-24-2024'),
      createDate('08-22-2024'),
      createDate('09-21-2024'),
      createDate('10-20-2024'),
      createDate('11-19-2024'),
      createDate('12-18-2024'),
    ],
    category: CATEGORIES.GANESH
    // information: 'Performed Every month on Bahula Chavithi'
  },
  {
    label: 'Masa Sivaratri / Rudra Homam',
    name: 'Masa Sivaratri Rudra Homam',
    amount: '300',
    availableDates: [
      createDate('01-10-2024'),
      createDate('02-08-2024'),
      createDate('03-08-2024'),
      createDate('04-07-2024'),
      createDate('05-06-2024'),
      createDate('06-05-2024'),
      createDate('07-04-2024'),
      createDate('08-02-2024'),
      createDate('09-01-2024'),
      createDate('10-01-2024'),
      createDate('10-30-2024'),
      createDate('11-29-2024'),
      createDate('12-29-2024'),
    ],
    category: CATEGORIES.SHIVA
  },
  {
    label: 'Surya Namasakaramulu',
    name: 'Surya Namasakaramulu',
    amount: '300',
    availableDates: [
      createDate('01-28-2024'),
      createDate('02-25-2024'),
      createDate('03-31-2024'),
      createDate('04-28-2024'),
      createDate('05-26-2024'),
      createDate('06-30-2024'),
      createDate('07-28-2024'),
      createDate('08-25-2024'),
      createDate('09-29-2024'),
      createDate('10-27-2024'),
      createDate('11-24-2024'),
      createDate('12-29-2024'),
    ],
    category: CATEGORIES.SPECIAL_EVENTS
  },
  {
    label: 'Dhanurmasa Prasadam',
    name: 'Dhanurmasa Prasadam',
    amount: '250',
    availableDates: [],
    category: CATEGORIES.SPECIAL_EVENTS,
    disabled: true
  },
  {
    name: 'Ganapathi Abhishekam',
    amount: '150',
    availableDates: [],
    category: CATEGORIES.GANESH
  },
  {
    label: 'Radha Saptami Surya Namaskarams',
    name: 'Radha Saptami Surya Namaskarams',
    amount: '500',
    availableDates: [],
    category: CATEGORIES.SPECIAL_EVENTS
  },
  {
    name: 'Laghunyasa Abhishekam',
    amount: '50',
    availableDates: [],
    category: CATEGORIES.SHIVA
  },
  {
    name: 'Shiva Panchamrutha Abhishekam',
    amount: '50',
    availableDates: [],
    category: CATEGORIES.SHIVA
  },
  {
    name: 'Ganapathi Panchamrutha Abhishekam',
    amount: '50',
    availableDates: [],
    category: CATEGORIES.GANESH
  },
  {
    name: 'Shiva Ashtottaram',
    amount: '20',
    availableDates: [],
    category: CATEGORIES.SHIVA
  },
  {
    name: 'Ganapathi Ashtottaram',
    amount: '20',
    availableDates: [],
    category: CATEGORIES.GANESH
  },
  {
    name: 'Ashtottaram',
    amount: '20',
    availableDates: [],
    category: CATEGORIES.SHARADA
  },
  {
    name: 'Swarna Bilwarchana',
    amount: '1500',
    availableDates: [],
    category: CATEGORIES.SHIVA
  },
  {
    name: 'Special Swarna Bilwarchana',
    amount: '3000',
    availableDates: [],
    category: CATEGORIES.SHIVA
  },
  {
    name: 'Special Swarna kamalam',
    amount: '3000',
    availableDates: [],
    category: CATEGORIES.SHARADA,
    disabled: true
  },
  {
    label: 'Sarva Abharana Bhushitha Swarna kamalam',
    name: 'Sarva Abharana Bhushitha Swarna kamalam',
    amount: '6000',
    availableDates: [],
    category: CATEGORIES.SHARADA
  },
  {
    label: 'Trishathi Mala Sahita Sarva Abharana Bhushita Swarna kamalam',
    name: 'Trishathi Swarna kamalam',
    amount: '7500',
    availableDates: [],
    category: CATEGORIES.SHARADA
  },
  {
    label: 'Vahana Pooja',
    name: 'Vahana Pooja',
    amount: '100',
    availableDates: [],
    category: CATEGORIES.OTHERS
  },
  {
    label: 'Sata Rudrabhishekam',
    name: 'Sata Rudrabhishekam',
    amount: '8000',
    availableDates: [
      createDate('10-31-2022'),
      createDate('11-07-2022'),
      createDate('11-14-2022'),
      createDate('11-21-2022'),
    ],
    category: CATEGORIES.SHIVA
  },
  {
    label: 'Bilwarchana',
    name: 'Bilwarchana',
    amount: '250',
    availableDates: [],
    category: CATEGORIES.SHIVA,
    disabled: true
  },
  {
    name: 'Udayasthamana Pooja',
    amount: '3000',
    availableDates: [],
    category: CATEGORIES.SHARADA
  },
  {
    name: 'Swarna Kamala Pooja',
    amount: '1500',
    availableDates: [],
    category: CATEGORIES.SHARADA
  },
  {
    name: 'Sri Chakrarchana Sarva Pooja',
    amount: '150',
    availableDates: [],
    category: CATEGORIES.SHARADA
  },
  {
    name: 'Sahasram',
    amount: '50',
    availableDates: [],
    category: CATEGORIES.SHARADA
  },
  {
    name: 'Sahasra Modaka Ganapathi Homam and Abhishekam',
    amount: '300',
    availableDates: [],
    category: CATEGORIES.GANAPATHI_NAVARATRI,
    disabled: true
  },
  {
    name: 'Durvarchana',
    amount: '250',
    availableDates: [
      createDate('09-23-2023'),
    ],
    category: CATEGORIES.GANAPATHI_NAVARATRI,
    disabled: true
  },
  {
    label: 'Ratha Seva Wooden Chariot (Every Friday/Pournami)',
    name: 'Ratha Seva Wooden Chariot',
    amount: '1500',
    availableDates: [],
    category: CATEGORIES.OTHERS,
    disabled: true
  },
  {
    label: 'Ratha Seva Silver Chariot (Every Friday/Pournami)',
    name: 'Ratha Seva Silver Chariot',
    amount: '2000',
    availableDates: [],
    category: CATEGORIES.OTHERS,
    disabled: true
  },
  {
    label: 'Kailasa Gouri Nomu Kanuka',
    name: 'Kailasa Gouri Nomu Kanuka',
    amount: '800',
    availableDates: [],
    category: CATEGORIES.OTHERS
  },
  {
    label: 'Suvasini Pooja',
    name: 'Suvasini Pooja',
    amount: '200',
    availableDates: [],
    category: CATEGORIES.OTHERS,
    disabled: true
  },
  {
    label: 'Chandi Parayana 1 Day',
    name: 'Parayana One Day',
    amount: '200',
    availableDates: [
      ...getContinuousDaysInRange(
        new Date('10-14-2023'),
        new Date('10-24-2023')
      )
    ],
    category: CATEGORIES.DASARA_NAVARATRI
    // disabled: true
  },
  {
    label: 'Chandi Parayana 9 Days',
    name: 'Parayana Nine Days',
    amount: '1500',
    availableDates: [
      ...getContinuousDaysInRange(
        new Date('10-14-2023'),
        new Date('10-24-2023')
      )
    ],
    category: CATEGORIES.DASARA_NAVARATRI,
    // disabled: true
  },
  {
    label: 'Dasara Suvasini Pooja',
    name: 'Dasara Suvasini Pooja',
    amount: '200',
    availableDates: [
      ...getContinuousDaysInRange(
        new Date('10-14-2023'),
        new Date('10-24-2023')
      )
    ],
    category: CATEGORIES.DASARA_NAVARATRI,
  },
  {
    label: 'Mahabhishekam (Only on Akshaya Thritiya and Mahalaya Amavasya',
    name: 'Mahabhishekam',
    amount: '12000',
    availableDates: [
      createDate('10-14-2023')
    ],
    category: CATEGORIES.DASARA_NAVARATRI,
    // disabled: true
  },
  {
    label: 'Sarva Seva',
    name: 'Sarva Seva',
    amount: '10000',
    availableDates: [
      ...getContinuousDaysInRange(
        new Date('10-14-2023'),
        new Date('10-24-2023')
      )
    ],
    category: CATEGORIES.DASARA_NAVARATRI,
    // disabled: true
  },
  {
    label: 'Dasara Udayastamana Pooja',
    name: 'Dasara Udayastamana Pooja',
    amount: '3000',
    availableDates: [
      ...getContinuousDaysInRange(
        new Date('10-14-2023'),
        new Date('10-24-2023')
      )
    ],
    category: CATEGORIES.DASARA_NAVARATRI,
    // disabled: true
  },
  {
    label: 'Dasara Chandi Homa Kanuka',
    name: 'Dasara Chandi Homa Kanuka',
    amount: '500',
    availableDates: [
      createDate('10-23-2023')
    ],
    category: CATEGORIES.DASARA_NAVARATRI,
    // disabled: true
  },
  {
    label:
      'Special Sata Rudrabhishekam (On Mahashivaratri/Shankar Jayanti/ Karthika Masam)',
    name: 'Special SataRudrabhishekam ',
    amount: '15000',
    availableDates: [
      createDate('03-08-2024'),
      createDate('02-18-2023'),
      createDate('11-07-2022'),
      createDate('11-14-2022'),
      createDate('11-21-2022'),
    ],
    category: CATEGORIES.SPECIAL_EVENTS,
    disabled: true
  },
  {
    label:
      'Sita Rama Kalyanam (On Sri Rama Navami)',
    name: 'Sri Sita Rama Kalyanam ',
    amount: '500',
    availableDates: [
      createDate('03-30-2023'),
      createDate('04-17-2024'),
    ],
    category: CATEGORIES.OTHERS,
    disabled: false
  },
  {
    label:
      'Sri Sita Rama Abhishekam',
    name: 'Sri Sita Rama Abhishekam ',
    amount: '100',
    availableDates: [
      ...getContinuousDaysInRange(
        new Date('03-22-2022'),
        new Date('03-30-2022')
      )
    ],
    category: CATEGORIES.OTHERS,
    disabled: false
  },
  {
    label:
      'Sri Sita Rama Abhishekam 10 days',
    name: 'Sri Sita Rama Abhishekam 10 days ',
    amount: '1000',
    availableDates: [
      createDate('03-22-2023'),
    ],
    category: CATEGORIES.OTHERS,
    disabled: false
  },
  {
    label:
      'Sri Sita Rama Kalyanam and Anna Prasada Kanuka',
    name: 'Sri Sita Rama Kalyanam and Anna Prasada Kanuka',
    amount: '10000',
    availableDates: [
      createDate('03-30-2023'),
    ],
    category: CATEGORIES.OTHERS,
    disabled: false
  },
  {
    label: 'Grahana Shanthi Homam (Only during eclipse)',
    name: 'Grahana Shanthi Pooja',
    amount: '300',
    availableDates: [
      createDate('10-26-2022'),
      createDate('11-09-2022'),
    ],
    category: CATEGORIES.OTHERS,
    disabled: false
  },
  {
    label: 'Lingodbhava Kala Abhishekam',
    name: 'Lingodbhava Abhishekam',
    amount: '100',
    availableDates: [createDate('03-08-2024')],
    category: CATEGORIES.MAHASHIVARATRI,
    disabled: false
  },
  {
    label: 'Girija Kalyanam (Next Day of Shivaratri)',
    name: 'Girija Kalyanam',
    amount: '250',
    availableDates: [createDate('03-09-2024')],
    category: CATEGORIES.MAHASHIVARATRI
  },
  {
    label: 'Swarna Vastra Alankara Seva (Fridays Only)',
    name: 'Swarna Vastra Alankara Seva',
    amount: '12000',
    availableDates: getContinuousDaysInRange(
      new Date('11-21-2020'),
      new Date('03-31-2021')
    ).filter(date => isFriday(date)),
    category: CATEGORIES.SHARADA
  }
];
